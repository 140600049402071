<template>
  <div id="subselection-category">
    <h4
      id="subselection-category-title"
      :class="{ marginBottom: !(subselection.type == 'CHECKBOX' && subselection.maximumSelected > 0) }"
    >
      {{ subselection.names.translation[language].name }}
      <span
        style="color: #198b4a;"
        v-if="subselection.mandatory"
      >*</span>
    </h4>
    <h5
      v-if="subselection.type == 'CHECKBOX' && subselection.maximumSelected > 0"
      id="subselection-category-maxselected"
    >*{{labels.cartModal.upTo[language]}} {{subselection.maximumSelected}} {{labels.cartModal.options[language]}}</h5>

    <div id="subselection-category-items">
      <SubselectionItem
        v-for="item in subselection.options"
        :key="item.id"
        :item="item"
        :categoryId="subselection.id"
        :maximumSelected="subselection.maximumSelected"
        :mandatory="subselection.mandatory"
        :type="subselection.type"
        :priceAggregated="subselection.priceAggregated"
        :productPrice="productPrice"
        :priceable="priceable"
      ></SubselectionItem>
    </div>
  </div>
</template>

<script>
import SubselectionItem from "./SubselectionItem.vue";
export default {
  name: "SubselectionCategory",
  components: {
    SubselectionItem,
  },
  props: ["subselection", "productPrice", "priceable"],
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
  },
};
</script>

<style scoped>
#subselection-category-items {
  display: grid;
  grid-template-columns: repeat(2, 51.5%);
  grid-gap: 20px 0px;
  margin-bottom: 40px;
}
@media screen and (max-width: 885px) {
  #subselection-category-items {
    grid-gap: 14px 0px;
    grid-template-columns: repeat(1, 105%);
  }
}

#subselection-category-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2px;
  color: #003340;
}

.marginBottom {
  margin-bottom: 12.5px !important;
}

#subselection-category-maxselected {
  font-size: 13px;
  margin-bottom: 12.5px;
  color: rgb(37, 37, 37);
}

@media screen and (max-width: 753px) {
  #subselection-category-title {
    font-size: 15px;
  }
}
</style>