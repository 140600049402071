import axios from "axios";
import { store } from "../store/store";
import { getResponseMessage } from "../assets/js/utils";

export default {
  async logout(authToken, jwt) {
    axios
      .post(
        "/authenticate/logout",
        {},
        {
          headers: {
            "AUTH-TOKEN": authToken,
            "AUTH-JWT": jwt,
          },
        }
      )
      .then()
      .catch((error) => console.log(error.response));
  },

  async enter(phone, storeId) {
    return axios
      .post(`/entrance?storeId=${storeId}`, phone)
      .then((response) => {
        return {
          status: 200,
        };
      })
      .catch((error) => {
        let message = {
          GRE: "",
          ENG: "",
        };

        if (error.response) {
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο";
          message.ENG = "Something went wrong, try again later";
        }

        return {
          status: 500,
          message: message,
        };
      });
  },

  async sendEntanceSMS(phone, channel) {
    return axios
      .post(`/sms/v2/send?channel=${channel}`, phone)
      .then((response) => {
        let message = {
          GRE: "Eπιτυχής αποστολή νέου κωδικού",
          ENG: "Successfully sent new code",
        };

        return {
          status: 200,
          message: message,
        };
      })
      .catch((error) => {
        let message = {
          GRE: "",
          ENG: "",
        };

        if (error.response) {
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο";
          message.ENG = "Something went wrong, try again later";
        }

        return {
          status: 500,
          message: message,
        };
      });
  },

  async verifyV2(phone) {
    return axios
      .post("/authenticate/v2/login", phone)
      .then((response) => {
        let responseData = response.data;
        let user = {
          user: responseData.authToken.user,
          authToken: responseData.authToken.token,
          jwt: responseData.jwt,
        };

        let message = {
          GRE: "Επιτυχής είσοδος",
          ENG: "Successfull entrance",
        };

        return {
          status: 200,
          message: message,
          user: user
        }
      })
      .catch((error) => {
        let message = {
          GRE: "",
          ENG: "",
        };

        if (error.response) {
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο";
          message.ENG = "Something went wrong, try again later";
        }

        return {
          status: 500,
          message: message,
        };
      })
  },

  async changePhone(phone) {
    return axios
      .post("/sms/send/change-phone", phone, {
        headers: {
          "AUTH-TOKEN": store.getters.getUserAuthToken,
          "AUTH-JWT": store.getters.getUserJWT,
        },
      })
      .then((response) => {
        let message = {
          GRE: "Eπιτυχής αποστολή νέου κωδικού",
          ENG: "Successfully sent new code",
        };

        return {
          status: 200,
          message: message,
        };
      })
      .catch((error) => {
        let message = {
          GRE: "",
          ENG: "",
        };

        if (error.response) {
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο";
          message.ENG = "Something went wrong, try again later";
        }

        return {
          status: 500,
          message: message,
        };
      });
  },

  async verifyChangedPhone(phone) {
    return axios
      .post("/user/change-phone", phone, {
        headers: {
          "AUTH-TOKEN": store.getters.getUserAuthToken,
          "AUTH-JWT": store.getters.getUserJWT,
        },
      })
      .then((response) => {
        let responseData = response.data;
        let user = {
          user: responseData.authToken.user,
          authToken: responseData.authToken.token,
          jwt: responseData.jwt,
        };

        let message = {
          GRE: "Επιτυχής αλλαγή",
          ENG: "Successfull change",
        };

        return {
          status: 200,
          message: message,
          user: user
        }
      })
      .catch((error) => {
        let message = {
          GRE: "",
          ENG: "",
        };

        if (error.response) {
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο";
          message.ENG = "Something went wrong, try again later";
        }

        return {
          status: 500,
          message: message,
        };
      })
  },

  async sendSMS(authToken, jwt, isResend) {
    store.dispatch("setResendVerificationCodeMessage", "");
    return axios
      .post(
        "/sms/send",
        {},
        {
          headers: {
            "AUTH-TOKEN": authToken,
            "AUTH-JWT": jwt,
          },
        }
      )
      .then((response) => {
        if (isResend) {
          store.dispatch("successfullResendOfVerificationCode");
          let message = {
            GRE: "Νεος αριθμός επιβεβαιώσης στάλθηκε με επιτυχία",
            ENG: "New verification code was sent succesfully",
          };
          store.dispatch("setResendVerificationCodeMessage", message);
        }
      })
      .catch((error) => {
        store.dispatch("failedResendOfVerificationCode");
        if (error.response) {
          let message = getResponseMessage(error.response.data.message);
          store.dispatch("setResendVerificationCodeMessage", message);
        } else {
          let message = {
            GRE: "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο",
            ENG: "Something went wrong, try again later",
          };
          store.dispatch("setResendVerificationCodeMessage", message);
        }
      });
  },

  async sendResetEmail(email, isResend) {
    return axios
      .post(
        "/email/send/reset",
        {
          email: email,
        },
        {}
      )
      .then((response) => {
        if (isResend) {
          return {
            success: true,
            message: {
              GRE: "Νεος 4-ψήφιος αριθμός στάλθηκε με επιτυχία",
              ENG: "New 4-digit number was sent succesfully",
            },
          };
        } else {
          return {
            success: true,
          };
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          return {
            success: false,
            message: getResponseMessage(error.response.data.message),
          };
        } else {
          return {
            success: false,
            message: {
              GRE: "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο",
              ENG: "Something went wrong, try again later",
            },
          };
        }
      });
  },

  async resetPassword(email, newPassword, verificationCode) {
    return axios
      .post(
        "/user/reset-password",
        {
          email: email,
          password: newPassword,
          verificationCode: verificationCode,
        },
        {}
      )
      .then((response) => {
        return {
          success: true,
          message: {
            GRE: "Ο κωδικός άλλαξε με επιτυχία",
            ENG: "Password changed successfully",
          },
        };
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          return {
            success: false,
            message: getResponseMessage(error.response.data.message),
          };
        } else {
          return {
            success: false,
            message: {
              GRE: "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο",
              ENG: "Something went wrong, try again later",
            },
          };
        }
      });
  },

  async updateV2(user, authToken, jwt) {
    store.dispatch("resetProfileChangesState");
    return axios
      .post("/user/v2/update", user, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        let responseData = response.data;
        let user = responseData.authToken.user;
        let authToken = responseData.authToken.token;
        let jwt = responseData.jwt;
        store.dispatch("successfullProfileChanges");
        store.dispatch("setUserData", {
          user: user,
          authToken: authToken,
          jwt: jwt,
        });

        return true;
      })
      .catch((error) => {
        store.dispatch("failedProfileChanges");
        let message = {
          GRE: "",
          ENG: "",
        };
        if (error.response) {
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο";
          message.ENG = "Something went wrong, try again later";
        }
        store.dispatch("setProfileChangesMessage", message);

        return false;
      });
  },

  async delete(authToken, jwt) {
    axios
      .post(
        "/user/delete",
        {},
        {
          headers: {
            "AUTH-TOKEN": authToken,
            "AUTH-JWT": jwt,
          },
        }
      )
      .then((response) => {
        store.dispatch("successfullUserDeletion");
        store.dispatch("setUserData", {});
      })
      .catch((error) => {
        console.log(error.response);
        store.dispatch("failedUserDeletion");
      });
  },

  async refreshTokens(authToken, jwt) {
    axios
      .get("/authenticate/tokens/validate/refresh", {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        let responseData = response.data;
        let user = responseData.user;
        let authToken = responseData.authToken;
        let jwt = responseData.jwt;
        store.dispatch("setUserData", {
          user: user,
          authToken: authToken,
          jwt: jwt,
        });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.message === "INVALID_AUTH_TOKEN") {
          store.dispatch("setUserData", {});
        }
      });
  },

  async refreshTokensV2(authToken, jwt) {
    axios
      .get("/authenticate/v2/tokens/validate/refresh", {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        let responseData = response.data;
        let user = responseData.user;
        let authToken = responseData.authToken;
        let jwt = responseData.jwt;
        store.dispatch("setUserData", {
          user: user,
          authToken: authToken,
          jwt: jwt,
        });
      })
      .catch((error) => {
        console.log("Error occured at refresh tokens")
        console.log(error.response);
        if (error.response.data.message === "INVALID_AUTH_TOKEN") {
          store.dispatch("setUserData", {});
        }
      });
  },

  async storeSavedCards() {
    axios
      .get("/creditCard/user", {
        headers: {
          "AUTH-TOKEN": store.getters.getUserAuthToken,
          "AUTH-JWT": store.getters.getUserJWT,
        },
      })
      .then((response) => {
        store.dispatch("setAvailableCards", response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  },

  async deleteCreditCard(cardToken) {
    return axios
      .delete("/creditCard/delete", {
        headers: {
          "AUTH-TOKEN": store.getters.getUserAuthToken,
          "AUTH-JWT": store.getters.getUserJWT,
          cardToken: cardToken,
        },
      })
      .then((response) => {
        return {
          status: response.status,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            status: error.response.status,
          };
        } else {
          return {
            status: 500,
          };
        }
      });
  },

  async unsubscribeFromNewsletter() {
    return axios
      .post(
        "/newsletter/opt-out",
        {},
        {
          headers: {
            "AUTH-TOKEN": store.getters.getUserAuthToken,
            "AUTH-JWT": store.getters.getUserJWT,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          return error.response;
        }

        return {
          status: 500,
        };
      });
  },

  async hasActiveMembership(restaurantId) {
    return axios
      .get(
        `/user/membership?restaurantId=${restaurantId}`,
        {
          headers: {
            "AUTH-TOKEN": store.getters.getUserAuthToken,
            "AUTH-JWT": store.getters.getUserJWT,
          },
        })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
  },

  async getAdminAccounts(restaurantIds, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/staff/admin-accounts?restaurantIds=${restaurantIds}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
      });
  },

  async temporaryLogin(storeId) {
    return axios
      .post(`/authenticate/login/tmp?storeId=${storeId}`, {})
      .then((response) => {
        let responseData = response.data;
        let user = {
          user: responseData.authToken.user,
          authToken: responseData.authToken.token,
          jwt: responseData.jwt,
        };

        let message = {
          GRE: "Επιτυχής είσοδος",
          ENG: "Successfull entrance",
        };

        return {
          status: 200,
          message: message,
          user: user
        }
      })
      .catch((error) => {
        let message = {
          GRE: "",
          ENG: "",
        };

        if (error.response) {
          message = getResponseMessage(error.response.data.message);
        } else {
          message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο";
          message.ENG = "Something went wrong, try again later";
        }

        return {
          status: 500,
          message: message,
        };
      })
  }
};
