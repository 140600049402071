<template>
  <div>
    <div @click="selectItem" id="subselection-category-item">
      <input
        v-if="type == 'RADIO_BUTTON'"
        class="input"
        type="radio"
        :checked="item.isSelected"
        :name="`sub`+categoryId"
        :id="item.id"
      />
      <input
        v-else-if="type == 'CHECKBOX'"
        class="input"
        type="checkbox"
        v-model="item.isSelected"
        @click.stop
        @change="onCheckboxChange($event)"
        :name="`sub`+categoryId"
        :id="item.id"
      />
      <p id="subselection-item-name">{{ item.names.translation[language].name }}</p>
      <p id="subselection-item-price" v-if="priceable">
        <span
          style="margin-right: 4px"
          :class="{
          discounted: isInHappyHour && item.price != 0,
        }"
        >{{isInHappyHour ? happyHourPrice : priceOfSubselection}}</span>
        €
      </p>
    </div>
    <small
      v-if="maximumSelectedReached"
      class="maximum-reached-info"
    >{{labels.cartModal.upTo[language]}} {{maximumSelected}} {{labels.cartModal.options[language]}}</small>
  </div>
</template>

<script>
export default {
  name: "SubselectionItem",
  props: ["item", "categoryId", "type", "priceAggregated", "productPrice", "maximumSelected", "mandatory", "priceable"],
  data() {
    return {
      maximumSelectedReached: false,
      timeout: null
    };
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate;
    },

    priceOfSubselection() {
      if (this.priceAggregated) {
        return (this.item.price + this.productPrice).toFixed(2);
      } else {
        return this.item.price.toFixed(2);
      }
    },

    happyHourPrice() {
      return (
        (this.priceOfSubselection * (100 - this.happyHourDiscountPercentage)) /
        100
      ).toFixed(2);
    },

    happyHourDiscountPercentage() {
      return this.$store.getters.getHappyHourDiscountPercentage;
    },

    isInHappyHour() {
      if (this.currentGreekDate == "" || this.currentGreekDate == undefined) {
        return false;
      }
      return this.$store.getters.isInHappyHour(this.currentGreekDate);
    },

  },
  methods: {
    selectItem: function () {
      let option = {
        categoryId: this.categoryId,
        option: this.item,
      };

      this.maximumSelectedReached = false;
      clearTimeout(this.timeout);
      this.timeout = null;

      let amountOfChecked = document.querySelectorAll(`input[name=sub${this.categoryId}]:checked`).length
      this.$store.dispatch("triggerSubselectionOption", option);

      if (this.type == 'CHECKBOX' && this.maximumSelected > 0 && amountOfChecked >= this.maximumSelected && this.item.isSelected) {
        this.maximumSelectedReached = true;
        this.$store.dispatch("triggerSubselectionOption", option);
        this.clearMaximumSelectedWarningAfter(2200);
      }
    },

    clearMaximumSelectedWarningAfter(milliseconds) {
      this.timeout = setTimeout(() => {
        if (this.maximumSelectedReached) {
          this.maximumSelectedReached = false;
        }
        clearTimeout(this.timeout);
      }, milliseconds);
    },

    onCheckboxChange($event) {
      let amountOfChecked = document.querySelectorAll(`input[name=sub${this.categoryId}]:checked`).length
      if (this.mandatory && amountOfChecked == 0) {
        $event.target.checked = true;
        this.item.isSelected = true
      } else {
        $event.target.checked = false;
        this.item.isSelected = !this.item.isSelected;
        this.selectItem();
      }
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
#subselection-category-item {
  display: flex;
  width: 95%;
  padding: 8px 8px;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  #subselection-category-item {
    padding: 6px 10px;
  }
}

.input {
  accent-color: #198b4a;
}
@media screen and (max-width: 800px) {
  .input {
    transform: scale(0.9);
  }
}

#subselection-item-name,
#subselection-item-price {
  margin: 0;
}

#subselection-item-name {
  display: flex;
  flex: 4;
  margin-left: 10px;
  font-size: 15px;
}

.maximum-reached-info {
  margin-left: 4px;
  color: rgb(161, 0, 0);
}

@media screen and (max-width: 800px) {
  #subselection-item-name {
    margin-left: 10px;
    flex: 4.5;
  }
}

@media screen and (max-width: 753px) {
  #subselection-item-name {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  #subselection-item-name {
    flex: 4;
    font-size: 12px;
  }
}

#subselection-item-price {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  text-align: right !important;
  margin-right: 5px;
  font-size: 13px;
  color: grey;
}
@media screen and (max-width: 320px) {
  #subselection-item-price {
    font-size: 12px;
  }
}

.discounted {
  font-weight: 500;
}
</style>